import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Menu = () =>  {
    const fadeIn = (e) => {
        console.log("click");
        const menu = e.currentTarget;
        const ele = document.getElementById("top-menu-sp");
        const links = ele.getElementsByTagName( 'a' );

        for(let i = 0; i < links.length; i++){
            links[i].addEventListener('click',function(){
                document.body.style.overflow = "scroll";
                menu.classList.remove("active");
                ele.classList.remove("active");
            }, false);
        }

        if (menu.classList.contains('active')) {
            menu.classList.remove("active");
            ele.classList.remove("active");
        } else {
            menu.classList.add("active");
            ele.classList.add("active");
        }
    }

    return(
        <div>
            <div className="menu_sp globalMenuSp" id="top-menu-sp">
                <div className="menu_bg"></div>
                <ul id="menu" className="">
                    <Link to="/#" className="menu-logo-link"><StaticImage src="../images/march-logo.png" alt="menu-logo" className="menu-logo" /></Link>
                    <li className="menu-li"><Link to="/#course">コース紹介</Link></li>
                    <li className="menu-li"><Link to="/#price">料金</Link></li>
                    <li className="menu-li"><Link to="/#profile">講師プロフィール</Link></li>
                    <li className="menu-li"><Link to="/#access">アクセス</Link></li>
                    <Link to="/#footer" className="menu-contact-link"><div className="contact menu-contact"><span>申込み/お問合せ</span></div></Link>
                </ul>
            </div>
            <div className="navToggle" onClick={fadeIn}><span></span><span></span><span></span></div>
        </div>
    )
}
export default Menu